jQuery(document).ready(function ($) {

  // $('section.blog-content-section_content-wrapper:not(:has(div.field-name-field-blog-image))').css('margin-top',
  // '1rem');

  //-- Sticky Header
  var mainHeader = $('.main-header-wrapper');
  if (mainHeader.length) {
    var elmHeight = $('.header-top').outerHeight(true);
    $(window).scroll(function () {

      var scrolltop = $(window).scrollTop();
      if (scrolltop > elmHeight) {
        if (!mainHeader.hasClass('sticky')) {
          mainHeader.addClass('sticky');
        }

      }
      else {
        mainHeader.removeClass('sticky');
      }
    })
  }

  $('.pure-menu-list li').hover(function () {
    $(this).find('.pure-menu-children').toggle();
  });

  $('.live-chat-tab').click(function () {
    $('.form-pop-up').css('display', 'flex');
  });

  $('.form-pop-up').click(function () {
    $('.form-pop-up').css('display', 'none');
  });

  if ($('.hero-section').length) {
    $('.how-we-help-section').addClass('home-page');
  }
  if ($('.interior-hero-section-widget').length) {
    if ($('.interior-hero-section-widget').next().hasClass('content-section-widget')) {
      $('.interior-hero-section-widget').next().addClass('follows-hero')
    }
  }

  // $(function() {
  //     var imageWrapperHeight = $('.intro-section').outerHeight();
  //     $('.intro-section > .container > .image-wrapper').css('height',
  // imageWrapperHeight );  $(window).resize(function(){ $('.intro-section >
  // .container > .image-wrapper').css('height', 0 ); var imageWrapperHeight =
  // $('.intro-section').outerHeight(); $('.intro-section > .container >
  // .image-wrapper').css('height', imageWrapperHeight ); }); });


  $('.testimonials-wrapper').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
  });

  $('.services-container').slick({
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          adaptiveHeight: true,
        }
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  var mainNav = $('.main-navigation').find('.pure-menu-list').first().clone();
  console.log(mainNav);
  var mobileMenuContainer = $('.mobile-menu').find('.pure-menu');
  $(mobileMenuContainer).append(mainNav);



  $('.mobile-nav-toggle').click(function () {
    $('.mobile-menu').slideToggle('fast');
  });

  function checkMobileMenu() {
    if ($('.mobile-menu').css('display', 'block')) {
      $('.mobile-menu').css('display', 'none');
    }
  }

  $(window).resize(function () {
    var windowWidth = $(document).width();
    if (windowWidth >= 1024) {
      checkMobileMenu();
    }
  });

//google map

// When the window has finished loading create our google map below
  google.maps.event.addDomListener(window, 'load', mapInit);
  function mapInit() {
    // Basic options for a simple Google Map
    // For more options see:
    // https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 15,

      // The latitude and longitude to center the map (always required)
      center: new google.maps.LatLng(32.735903, -96.275787), // 210 E. Moore
                                                             // Ave.  Terrell,
                                                             // TX   75160

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [{
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{"color": "#d9d9d9"}, {"lightness": 17}]
      }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"color": "#e5e5e5"}, {"lightness": 20}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 17}]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 29}, {"weight": 0.2}]
      }, {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 18}]
      }, {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 16}]
      }, {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{"color": "#e5e5e5"}, {"lightness": 21}]
      }, {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{"color": "#dedede"}, {"lightness": 21}]
      }, {
        "elementType": "labels.text.stroke",
        "stylers": [{"visibility": "off"}, {"color": "#f2f2f2"}, {"lightness": 16}]
      }, {
        "elementType": "labels.text.fill",
        "stylers": [{"saturation": 36}, {"color": "#313131"}, {"lightness": 40}]
      }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"}]}, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
      }, {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
      }]
    };

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    // Let's also add a marker while we're at it
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(32.735903, -96.275787),
      map: map,
      title: 'Get Directions!'
    });

    google.maps.event.addListener(marker, 'click', function () {
      window.open('http://www.google.com', '_blank');
    });
  }

  $.validator.addMethod("checkPhoneValue", function (value) {
    var invalidPhoneNumbers = ['(000) 000-0000', '(111) 111-1111', '(222) 222-2222', '(333) 333-3333', "(444) 444-4444", "(555) 555-5555", "(666) 666-6666", "(777) 777-7777", "(888) 888-8888", "(999) 999-9999", "(123) 456-7890"];
    return $.inArray(value, invalidPhoneNumbers) === -1;
  }, 'Please enter valid phone number.');

  // Validation
  $("#contactUsPageForm").validate({
    ignore: ":hidden",
    rules: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      phoneNumber: {
        required: true,
        phoneUS: true,
        checkPhoneValue: true
      },
      _replyto: {
        required: true,
        email: true
      }
    },
    messages: {
      firstName: "Please enter your first name.",
      lastName: "Please enter your last name.",
      phoneNumber: "Please enter a valid phone number.",
      _replyto: "Please enter a valid email."
    }
  });

});
